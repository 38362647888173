import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import ImageUploading from "react-images-uploading";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";
import { URL } from "../../Redux/common/url";
import Select from "react-select";
function SubCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const users_list = useSelector((state) =>
    state?.allapi?.get_sub_category ? state?.allapi?.get_sub_category : {}
  );
  const get_main_all_category = useSelector((state) =>
    state?.allapi?.get_main_all_category
      ? state?.allapi?.get_main_all_category
      : []
  );

  console.log(users_list);

  const listdata = users_list?.docs ? users_list?.docs : [];

  const [subList, setsubList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const dropList = get_main_all_category?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [get_main_all_category]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.deletesubCategory({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  console.log(cateDetail);

  const [images, setImages] = useState([]);
  const maxNumber = 1;

  console.log(images);

  const [Imagesfe, setImagesfe] = useState("");
  const onChangefe = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImagesfe(imageList);
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  const [selectedOptionUpdate, setSelectedOptionUpdate] = useState(null);

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);
    const selectCate = subList.find((edata) => edata?._id == e?.parentId?._id);

    setSelectedOptionUpdate(selectCate);
    // setImagesfe([{ data_url: URL.API_BASE_URL + e.image }]);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    const data = new FormData();
    data.append("name", cateDetail?.name);
    // data.append('description', categoryUpPage)
    data.append("meta_title", cateDetail?.meta_title);
    data.append("meta_description", cateDetail?.meta_description);
    data.append("cate_id", cateDetail?._id);
    data.append("pageNo", page);
    if (Imagesfe[0]?.file) {
      // roledata?.role_name
      data.append("image", Imagesfe[0]?.file);
    }
    dispatch(
      allapiAction.updateperentsubCate({
        name: cateDetail?.name,
        // description: categoryUpPage,
        meta_title: cateDetail?.name,
        meta_description: cateDetail?.name,
        parentId: selectedOptionUpdate?._id,
        pageNo: page,
        cate_id: cateDetail?._id,
      })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});
  console.log(cateDetailAdd);

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);
    setAddModalOpen(true);
  };

  const [aboutPage, setaboutPage] = useState("");
  const handleOkAdd = () => {
    if (!cateDetailAdd?.name) {
      toast.error("please enter name");
    } else {
      //   const data = new FormData();
      //   data.append("page", 1);
      //   data.append("name", cateDetailAdd?.name);
      //   // data.append('description', aboutPage)
      //   data.append('meta_title', cateDetailAdd?.meta_title)
      //   data.append('meta_description', cateDetailAdd?.meta_description)
      // data.append('categoryName', 'test cate')
      //   data.append('parentId', '11')
      //   data.append('sellerId', '20')
      //   data.append('image', images[0]?.file)

      dispatch(
        allapiAction.subcreateCategory({
          name: cateDetailAdd?.name,

          meta_title: cateDetailAdd?.name,
          meta_description: cateDetailAdd?.name,
          parentId: selectedOption?._id,
        })
      );

      setcateDetailAdd({ name: "" });
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    dispatch(allapiAction.statusSubCategory({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //       <div style={{width:"100px"}}>
    //         <img  style={{width:"100%"}} src={URL?.API_BASE_URL +item?.image} alt="" />
    //       </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
    },

    {
      title: "Perent Category",
      dataIndex: "parentId",
      key: "parentId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.parentId?.name}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.status}>
              <button
                className={
                  item?.is_status == "Active"
                    ? "success-button ml-3"
                    : "delete-button ml-3"
                }
                onClick={() =>
                  hendleToStatus(
                    item?._id,
                    item?.is_status == "Active" ? "Inactive" : "Active"
                  )
                }
              >
                {item?.is_status}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
          <Tooltip title="Edit">
              <span
                className="cursor-pointer  delete-button6 mr-3"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon1" /> Edit
              </span>
            </Tooltip>
           
            <Tooltip title="Delete">
              <span
                className="cursor-pointer delete-button5  "
                onClick={() => {
                  showDeleteConfirm(item?._id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon1 " fill="#ffffff" />{" "}
                Delete
              </span>
            </Tooltip>
            {/* <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?._id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.getsubCategoryList(1));
    dispatch(allapiAction.getcateAllList({}));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.allcategorySearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.getsubCategoryList(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.getsubCategoryList(e));

      return () => {};
    }
  };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Sub Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <Select
            defaultValue={selectedOptionUpdate}
            onChange={setSelectedOptionUpdate}
            options={subList}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="meta title"
            name="meta_title"
            value={cateDetail?.meta_title}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="meta description"
            name="meta_description"
            value={cateDetail?.meta_description}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col> */}
      </Modal>

      {/* create */}
      <Modal
        title="Create Sub Category"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <Select
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={subList}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="meta title"
            name="meta_title"
            value={cateDetailAdd?.meta_title}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="meta description"
            name="meta_description"
            value={cateDetailAdd?.meta_description}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col> */}
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Sub Category "}
            subHeading={`Total ${users_list?.totalDocs || 0} Sub Category`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.totalDocs ? users_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default SubCategory;
