import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import HeadingBack from "../component/HeadingBack";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
function Distribution() {
  const [datesele, setdatesele] = useState(new Date());
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setpageNo] = useState(1);

  const dispatch = useDispatch();
  const month_report_2 = useSelector((state) =>
    state?.allapi?.month_report_2 ? state?.allapi?.month_report_2 : {}
  );
  const month_report = month_report_2?.docs ? month_report_2?.docs : [];
  useEffect(() => {
    setPageCount(month_report_2?.totalPages);
  }, [month_report_2]);

  useEffect(() => {
    const date = new Date();

    const year = date.getFullYear(); // 2023
    const month = date.getMonth() + 1;
    console.log(year);
    console.log(month);

    // dispatch(
    //   allapiAction.teamCommissionByDateBypagi({ year, month, pageNumber: 1 })
    // );
    // teamCommissionByDate
  }, []);

  const [Loder, setLoder] = useState(false);
  console.log(Loder);
  const handlePageClick = async (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const data = event?.selected + 1;

    setpageNo(data);

    // if (!userSearch) {
    const date = datesele;

    const year = date.getFullYear(); // 2023
    const month = date.getMonth() + 1;
    console.log(year);
    console.log(month);
    setLoder(true);
    const finaldata = await dispatch(
      allapiAction.commissiondistribution({ year, month })
    );
    setLoder(false);
    console.log(finaldata);
    return () => {};
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  };

  const [first, setfirst] = useState("");
  const hendleTosubmit = async (e) => {
    if (!first) {
      toast.error("select month");
    } else {
      const date = new Date(first);
      setdatesele(date);
      const year = date.getFullYear(); // 2023
      const month = date.getMonth() + 1;
      console.log(year);
      console.log(month);
      setpageNo(1);
      setLoder(true);
      const finaldata = await dispatch(
        allapiAction.commissiondistribution({ year, month })
      );
      //   dispatch(allapiAction.commissiondistribution({ year, month }));
      setLoder(false);
    }
  };
  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Month Reports"}
            // subHeading={`Total ${get_product_list?.totalDocs || 0} Product`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <input
            type="month"
            className="form-control"
            onChange={(e) => {
              setfirst(e?.target?.value);
            }}
          />

          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>
      </div>
      <div className="loadingbox">
        {Loder ? (
          "Loading -----"
        ) : (
          <button
            className="btn btn-primary"
            onClick={(e) => {
              hendleTosubmit(e);
            }}
          >
            Commission Distribution
          </button>
        )}
      </div>
    </div>
  );
}

export default Distribution;
