import React, { useEffect, useState } from "react";
import {
  DashboardOutlined,
  UsergroupAddOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
  FileOutlined,
  ApartmentOutlined,
  FileSearchOutlined,
  SearchOutlined,
  FormOutlined,
  FileDoneOutlined,
  UngroupOutlined,
  MoneyCollectOutlined,
  ControlOutlined,
  ContainerOutlined,
  MessageOutlined,
  FileImageOutlined,
  SlidersOutlined,
  ProjectOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import SidebarFooter from "./SidebarFooter";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { useBasePath } from "../../hooks/useBasePath";
import SvgIcon from "../../const/SvgIcon";
import { ShowMenu } from "../../utils";
const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();

  return (
    <Sider
      className="layout_sidebar"
      collapsible
      collapsed={collapsed}
      width={240}
      trigger={!collapsed ? <SidebarFooter /> : null}
    >
      <Logo collapsed={collapsed} />
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#15283C" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard Management",
              onClick: () => {
                navigate("/");
              },
            },
            {
              key: "users",
              icon: <UserOutlined />,
              label: "Users",
              style: { display: ShowMenu(["Users"]) },
              onClick: () => {
                navigate("/users");
              },
            },
            {
              key: "wallet",
              icon: <MoneyCollectOutlined />,
              label: "Wallet",
              style: { display: ShowMenu(["Wallet"]) },
              onClick: () => {
                navigate("/wallet");
              },
            },

            {
              key: "sale",
              icon: <FileSearchOutlined />,
              label: "Sale",
              style: { display: ShowMenu(["Sale"]) },
              onClick: () => {
                navigate("/sale");
              },
            },
            {
              key: "category",
              icon: <ProjectOutlined />,
              label: "Product",
              style: {
                display: ShowMenu([
                  "brands",
                  "category",
                  "Sub category",
                  "Sub Sub category",
                  "Product",
                ]),
              },
              children: [
                {
                  key: "brands",
                  label: "Brands",
                  icon: (
                    <img src="/brand.png" alt="" style={{ width: "20px" }} />
                  ),
                  style: { display: ShowMenu(["brands"]) },
                  onClick: () => {
                    navigate("/brands");
                  },
                },
                {
                  key: "category",
                  label: "Category",
                  icon:  <img src="/cate.png" alt="" style={{ width: "20px" }} />,
                  style: { display: ShowMenu(["category"]) },
                  onClick: () => {
                    navigate("/category");
                  },
                },

                {
                  key: "sub-category",
                  label: "Sub category",
                  icon:  <img src="/cate.png" alt="" style={{ width: "20px" }} />,
                  style: { display: ShowMenu(["Sub category"]) },
                  onClick: () => {
                    navigate("/sub-category");
                  },
                },
                {
                  key: "sub-sub-category",
                  label: "Sub Sub category",
                  icon:  <img src="/cate.png" alt="" style={{ width: "20px" }} />,
                  style: { display: ShowMenu(["Sub Sub category"]) },
                  onClick: () => {
                    navigate("/sub-sub-category");
                  },
                },
                {
                  key: "product",
                  label: "Product",
                  icon: <ShoppingOutlined />,
                  style: { display: ShowMenu(["Product"]) },
                  onClick: () => {
                    navigate("/product");
                  },
                },
              ],
            },

            {
              key: "sale-return",
              icon: <ApartmentOutlined />,
              label: "Sale Return",
              style: { display: ShowMenu(["Sale Return"]) },
              onClick: () => {
                navigate("/sale-return");
              },
            },
            {
              key: "mq-challenge",
              icon: <QuestionCircleOutlined />,
              label: "Mq Challenge",
              style: { display: ShowMenu(["Mq Challenge"]) },
              onClick: () => {
                navigate("/mq-challenge");
              },
            },
            {
              key: "duqe-challenge",
              icon: <QuestionCircleOutlined />,
              label: "Duqe Challenge",
              style: { display: ShowMenu(["Duqe Challenge"]) },
              onClick: () => {
                navigate("/duqe-challenge");
              },
            },
            {
              key: "month-reports",
              icon: <FileDoneOutlined />,
              label: "Month Reports",
              style: { display: ShowMenu(["Month Reports"]) },
              onClick: () => {
                navigate("/month-reports");
              },
            },
            {
              key: "commission-distribution",
              icon: <UngroupOutlined />,
              label: "Commission Distribution",
              style: { display: ShowMenu(["Commission Distribution"]) },
              onClick: () => {
                navigate("/commission-distribution");
              },
            },
            {
              key: "gpg-by-admin",
              icon: <QuestionCircleOutlined />,
              label: "Gpg by admin",
              style: { display: ShowMenu(["Gpg by admin"]) },
              onClick: () => {
                navigate("/gpg-by-admin");
              },
            },

            {
              key: "ticket",
              icon: <ContainerOutlined />,
              label: "Ticket",
              style: { display: ShowMenu(["Ticket"]) },
              onClick: () => {
                navigate("/ticket");
              },
            },
            {
              key: "blog",
              icon: <FormOutlined />,
              label: "Blog",
              style: { display: ShowMenu(["Blog"]) },
              onClick: () => {
                navigate("/blog");
              },
            },
            {
              key: "enquiry",
              icon: <SearchOutlined />,
              label: "Enquiry",
              style: { display: ShowMenu(["Enquiry"]) },
              onClick: () => {
                navigate("/enquiry");
              },
            },
            {
              key: "slider",
              icon: <SlidersOutlined />,
              label: "Slider",
              style: { display: ShowMenu(["Slider"]) },
              onClick: () => {
                navigate("/slider");
              },
            },
            // {
            //   key: "setting",
            //   icon: <SettingOutlined />,
            //   label: "Setting",
            //   style: { display: ShowMenu(["Setting"]) },
            //   onClick: () => {
            //     navigate("/setting");
            //   },
            // },
            {
              key: "faq",
              icon: <MessageOutlined />,
              label: "Faq",
              style: { display: ShowMenu(["Faq"]) },
              onClick: () => {
                navigate("/faq");
              },
            },
            // {
            //   key: "pages",
            //   icon: <FileImageOutlined />,
            //   label: "Pages",
            //   style: { display: ShowMenu(["Pages"]) },
            //   onClick: () => {
            //     navigate("/pages");
            //   },
            // },

            {
              key: "gpg-ct",
              icon: <QuestionCircleOutlined />,
              label: "Gpg",
              style: { display: ShowMenu(["Gpg Ct", "Gpg Duqe", "Gpg Mq"]) },
              children: [
                {
                  key: "gpg-ct",
                  label: "Gpg Ct",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Gpg Ct"]) },
                  onClick: () => {
                    navigate("/gpg-ct");
                  },
                },
                {
                  key: "gpg-mq",
                  label: "Gpg Mq",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Gpg Mq"]) },
                  onClick: () => {
                    navigate("/gpg-mq");
                  },
                },

                {
                  key: "gpg-duqe",
                  label: "Gpg Duqe",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Gpg Duqe"]) },
                  onClick: () => {
                    navigate("/gpg-duqe");
                  },
                },

                // {
                //     key: 'corporate-governance',
                //     label: 'Corporate Governance',
                //     icon: <QuestionCircleOutlined />,
                //     style: { display: ShowMenu(["investor Pages"]) },
                //     onClick: () => {
                //         navigate("/investor-corporate-governance")
                //     },
                // },
              ],
            },
            // {
            //     key: 'seo-management',
            //     icon: <SvgIcon.seoManagementIcon />,
            //     label: 'SEO Management',
            //     style: { display: ShowMenu(["seo", 'Seo Pages']) },
            //     children: [
            //         {
            //             key: 'seo',
            //             label: 'SEO',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["Seo Pages"]) },
            //             onClick: () => {
            //                 navigate("/seo-management")
            //             },
            //         },
            //     ]
            // },
            // {
            //     key: 'ticket-management',
            //     icon: <FileOutlined />,
            //     label: 'Ticket Management',
            //     style: { display: ShowMenu(["Ticket Management"]) },
            //     onClick: () => {
            //         navigate("/ticket-management")
            //     }
            // },
            // {
            //     key: 'hr-management',
            //     icon: <SvgIcon.HrIcon />,
            //     label: 'HR Management',
            //     style: { display: ShowMenu(["Job Applications", "Job Posts"]) },
            //     children: [
            //         {
            //             key: 'job-applications',
            //             label: 'Job Applications',
            //             icon: <FileOutlined />,
            //             style: { display: ShowMenu(["Job Applications"]) },
            //             onClick: () => {
            //                 navigate("job-applications")
            //             },
            //         },
            //         {
            //             key: 'job-post',
            //             label: 'Job Posts',
            //             icon: <FileOutlined />,
            //             style: { display: ShowMenu(["Job Posts"]) },
            //             onClick: () => {
            //                 navigate("jobs")
            //             },
            //         },

            //     ]
            // },
            // {
            //     key: 'user-management',
            //     icon: < UsergroupAddOutlined />,
            //     label: 'Users Management',
            //     style: { display: ShowMenu(["Users", "Roles"]) },
            //     children: [
            //         {
            //             key: 'users',
            //             label: 'Users',
            //             icon: <UserOutlined />,
            //             style: { display: ShowMenu(["Users"]) },
            //             onClick: () => {
            //                 navigate("/users")
            //             },
            //         },
            //         {
            //             key: 'roles',
            //             label: 'Roles',
            //             icon: <UserOutlined />,
            //             style: { display: ShowMenu(["Roles"]) },
            //             onClick: () => {
            //                 navigate("/roles")
            //             },
            //         }
            //     ]
            // },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
